import React from "react";

import img1 from "../../Assets/im1.jpg"
import img2 from "../../Assets/im2.jpg"
import img3 from "../../Assets/im3.jpg"
import img4 from "../../Assets/im4.jpg"
import img5 from "../../Assets/im5.jpg"
import img6 from "../../Assets/img6.jpg"
import img7 from "../../Assets/img7.jpg"
import img8 from "../../Assets/img8.jpg"
import img9 from "../../Assets/img9.jpg"
import img10 from "../../Assets/img10.jpg"
import img11 from "../../Assets/img11.jpg"


const Gallery = () => {
  return (
    <div className="w-full border-0 border-red-900 p-8">
      <div className="flex flex-wrap gap-6 justify-center">
        <div className="border-0 rounded-2xl border-red-900 w-[400px] h-[350px]">
            <img src={img1} alt="" className="w-full h-full border-0 rounded-2xl border-black hover:opacity-70" />
        </div>
        <div className="border-0 rounded-2xl border-red-900 w-[400px] h-[350px]">
            <img src={img10} alt="" className="w-full h-full border- rounded-2xl border-black hover:opacity-70" />
        </div>
        <div className="border-0 rounded-2xl border-red-900 w-[400px] h-[350px]">
            <img src={img6} alt="" className="w-full h-full border-0 rounded-2xl border-black hover:opacity-70" />
        </div>
        <div className="border-0 rounded-2xl border-red-900 w-[400px] h-[350px]">
            <img src={img3} alt="" className="w-full h-full border-0 rounded-2xl border-black hover:opacity-70" />
        </div>
        <div className="border-0 rounded-2xl border-red-900 w-[400px] h-[350px]">
            <img src={img4} alt="" className="w-full h-full border-0 rounded-2xl border-black hover:opacity-70" />
        </div>
        <div className="border-0 rounded-2xl border-red-900 w-[400px] h-[350px]">
            <img src={img11} alt="" className="w-full h-full border- rounded-2xl border-black hover:opacity-70" />
        </div>
        <div className="border-0 rounded-2xl border-red-900 w-[400px] h-[350px]">
            <img src={img5} alt="" className="w-full h-full border-0 rounded-2xl border-black hover:opacity-70" />
        </div>
       
        <div className="border-0 rounded-2xl border-red-900 w-[400px] h-[350px]">
            <img src={img7} alt="" className="w-full h-full border-0 rounded-2xl border-black hover:opacity-70" />
        </div>
        <div className="border-0 rounded-2xl border-red-900 w-[400px] h-[350px]">
            <img src={img8} alt="" className="w-full h-full border-0 rounded-2xl border-black hover:opacity-70" />
        </div>
        <div className="border-0 rounded-2xl border-red-900 w-[400px] h-[350px]">
            <img src={img9} alt="" className="w-full h-full border-0 rounded-2xl border-black hover:opacity-70" />
        </div>
        
        <div className="border-0 rounded-2xl border-red-900 w-[400px] h-[350px]">
            <img src={img2} alt="" className="w-full h-full border-0 rounded-2xl border-black hover:opacity-70" />
        </div>
        
      </div>
    </div>
  );
};

export default Gallery;
