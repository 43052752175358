import React, { useEffect, useState } from "react";
import Slider from "./Slider";
import card1 from "../../Assets/card1.png";
import card2 from "../../Assets/card2.png";
import card3 from "../../Assets/card3.png";
import "./Course.css";
import img1 from "../../Assets/img1.png";
import { Link, useNavigate } from "react-router-dom";
import CarouselIndicatorsControlsInside from "./CarouselControlsInside";
import Data1 from "../Json Data/Data1";

const Courses = () => {

  const [selected, setSelected] = useState();

  console.log(selected);

  const navigat = useNavigate();

  const handleBookDemoClick = () => {
    navigat('/workshop');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const button_items = [
    {
      id: 3,
      text: "Class - 3",
    },
    {
      id: 4,
      text: "Class - 4",
    },
    {
      id: 5,
      text: "Class - 5",
    },
    {
      id: 6,
      text: "Class - 6",
    },
    {
      id: 7,
      text: "Class - 7",
    },
    {
      id: 8,
      text: "Class - 8",
    },
    {
      id: 9,
      text: "Class - 9",
    },
    {
      id: 10,
      text: "Class - 10",
    },
    {
      id: 11,
      text: "Class - 11",
    },
    {
      id: 12,
      text: "Class - 12",
    },
  ];

  const navigate = useNavigate();
  return (
    <div className="w-full h-auto">
      {/* section 1 */}
      <section className="border-0 tablet:w-[80%] ml-auto mr-auto border-red-600 flex flex-col justify-start gap-20  items-center h-auto">
        <div className="w-full tablet:h-[50vh]  border-0 tablet:rounded-3xl  shadow-xl border-black">
          <Slider />
        </div>

        <section className="border-0 border-black w-full h-auto flex mobiles:items-center tablet:items-start flex-col gap-6">
          <h1 className="text-[32px] font-semibold leading-10">
            Explore courses{" "}
            <span className="text-[18px] font-thin">(Class 6 - 10)</span>
          </h1>
          <div className="h-auto border-0 border-red-400 flex gap-2 tablet:justify-between mobiles:justify-center flex-wrap ">
            {/* card  */}
            <div class="relative border-2 shadow-2xl   border-gray-400 rounded-3xl w-[360px] h-[360px] ">
              <img
                class="h-full w-full  object-cover rounded-3xl"
                src={card3}
                alt="Random"
              />
              <div class="absolute inset-0 bg-transparent opacity-60 rounded-3xl"></div>
              <div class="absolute inset-0 flex flex-col p-3 ml-4 w-2/3">
                <div className="heading border-0 border-red-400">
                  <h1 className="text-sky-500 font-medium leading-9">
                    Class 6 - 7{" "}
                  </h1>
                  <h2 className="text-black font-semibold roboto">
                    Beginner Module
                  </h2>
                </div>
                <div className="main border-0 h-[70%] pt-4 border-red-400 flex flex-col gap-2 justify-start">
                  <button className=" btnnormal w-auto"> Robotics </button>
                  <button className=" btnnormal "> Basic coding </button>
                  <button className=" btnnormal "> Simple Machines </button>
                </div>
                <div className="button border-0 border-red-400">
                   <button  onClick={() => navigate("/courses/item/all")} className=" btnhover">Explore Course</button> 
                </div>
              </div>
            </div>
            {/* card end  */}
            {/* card  */}
            <div class="relative border-2 shadow-2xl   border-gray-400 rounded-3xl w-[360px] h-[360px] ">
              <img
                class="h-full w-full  object-cover rounded-3xl"
                src={card2}
                alt="Random1"
              />
              <div class="absolute inset-0 bg-transparent opacity-60 rounded-3xl"></div>
              <div class="absolute inset-0 flex flex-col p-3 ml-4 w-2/3">
                <div className="heading border-0 border-red-400">
                  <h1 className="text-sky-500 font-medium leading-9">
                    Class 8 - 9{" "}
                  </h1>
                  <h2 className="text-black font-semibold roboto">
                    Intermediate Module
                  </h2>
                </div>
                <div className="main border-0 h-[70%] pt-4 border-red-400 flex flex-col gap-2 justify-start">
                  <button className=" btnnormal w-auto">
                    {" "}
                    Robot construction{" "}
                  </button>
                  <button className=" btnnormal "> Sensors Intro. </button>
                  <button className=" btnnormal "> Programming Robots </button>
                </div>
                <div className="button border-0 border-red-400">
                  <button
                    onClick={() => navigate("/courses/item/all")}
                    className=" btnhover "
                  >
                    {" "}
                    Explore Course
                  </button>
                </div>
              </div>
            </div>
            {/* card end  */}
            {/* card  */}
            <div class="relative border-2 shadow-2xl   border-gray-400 rounded-3xl w-[360px] h-[360px] ">
              <img
                class="h-full w-full  object-cover rounded-3xl"
                src={card1}
                alt="Random2"
              />
              <div class="absolute inset-0 bg-transparent opacity-60 rounded-3xl"></div>
              <div class="absolute inset-0 flex flex-col p-3 ml-4 w-2/3">
                <div className="heading border-0 border-red-400">
                  <h1 className="text-sky-500 font-medium leading-9">
                    Class 10{" "}
                  </h1>
                  <h2 className="text-black font-semibold roboto">
                    Advance Module
                  </h2>
                </div>
                <div className="main border-0 h-[70%] pt-4 border-red-400 flex flex-col gap-2 justify-start">
                  <button className=" btnnormal w-auto"> Applications </button>
                  <button className=" btnnormal "> Advance Programming </button>
                  <button className=" btnnormal "> Autonomous Robots </button>
                  <button className=" btnnormal "> AI Introduction </button>
                </div>
                <div className="button border-0 border-red-400">
                  <button
                   onClick={() => navigate("/courses/item/all")}
                    className=" btnhover "> Explore Course</button>
                </div>
              </div>
            </div>
            {/* card end  */}
          </div>
        </section>
      </section>

      <br />
      <br />
      
      <section className="bg-gray-100 w-full h-[60vh]">
        <div className="h-full tablet:w-[80%] relative ml-auto mr-auto border-0 gap-2 flex  justify-between border-black">
          <div className="tablet:w-1/2 absolute left-0 p-2 z-20 border-0 h-full flex flex-col justify-center items-start border-black">
            <h1 className="text-[32px] tablet:w-full mobiles:w-3/4 roboto font-extrabold leading-10 pt-6 pb-6">
              Book your
              <span className="text-[#ff5b42]"> Free Demo</span> session
            </h1>
            <h2 className="pb-4 tablet:w-full mobiles:w-3/5">Get a free academic counselling session</h2>
            <button className=" border-2 border-[#ff5b42] rounded-md p-2 tablet:w-2/3 mobiles:1/3 hover:bg-[#ff5b42] hover:text-white text-[1.1rem] font-medium"  onClick={handleBookDemoClick}>
              Book Free Demo
            </button>
          </div>
          <div className="w-1/2 border-0 h-full absolute right-0 border-black">
            <img src={img1} className="h-full" alt="demoimg" />
          </div>
        </div>
      </section>
      <br />
      <br />

      {/* Courses  */} 
      <section id="courses" className="border-0 tablet:w-[80%] ml-auto mr-auto px-3 border-red-600 flex flex-col justify-start gap-6  items-start h-auto">
        <h1 className="text-[32px] font-semibold ">
          Explore courses{" "}
          <span className="text-[18px] font-thin">(Class 6 - 10)</span>
        </h1>
        <div className="w-full border-0 border-black flex tablet:justify-start mobiles:justify-center gap-4 flex-wrap">
          {button_items.map((item) => {
            return (
              <button className={` ${selected == item.id ? "bg-gray-500":"bg-white"} btn-course p-2  tablet:w-36 mobiles:w-[90%] h-10 border-2 border-gray-500 rounded-xl`}
              value={item.id}
              onClick={(e) => {
                setSelected(e.target.value);
              }}
              >
                {" "}
                {item.text} {" "}
              </button>
            );
          })}
        </div>
        
        <div className="w-full">
          <div className="flex justify-between w-full">
            <div className="text-[30px] font-medium">LIVE Classes</div>
            <Link to="/courses/item/all" className="text-orange-600 underline">View all</Link >
          </div>
          <div className="w-full pt-4">
              <CarouselIndicatorsControlsInside selected={selected} />
          </div>
        </div>

      </section>

      <br /><br /><br /><br /><br /><br /><br />
    </div>
  );
};

export default Courses;
