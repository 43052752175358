import React from "react";
import "./Hero.css";
import { useNavigate } from "react-router-dom";
const Hero = () => {
  const navigate = useNavigate();

  const handleBookDemoClick = () => {
    navigate('/workshop');
  };

  return (
    <>
      <section className="mobilel:h-auto mobiles:h-auto backgr-hero  flex  flex-wrap mobilel:justify-start mobiles:justify-center">
      <div class="font-sans text-[#333] max-w-[90%]  tablet:h-[80vh] mobiles:h-[50vh] border-0 flex justify-center flex-col border-red-800 max-mobilel:max-w-md mx-auto">
      <div class="grid mobilel:grid-cols-2 h-[80%] border-0 border-red-800  items-center mobilel:gap-8 gap-6">
        <div class="max-mobilel:order-1 max-mobilel:text-start  relative">
          <h2 class="laptop:text-6xl text-justify	 mobilel:text-5xl text-3xl font-bold mb-4 mobilel:!leading-[66px]"><span class="text-[#1e337f]">STEAM LAB</span> <span class="text-[#3d5ea3] laptop:text-7xl mobilel:text-6xl text-4xl font-medium">Robotics Revolution</span> </h2>
          <p class="mt-6 text-base text-white leading-relaxed"> Fusing Science, Tech, Engineering, Arts & Maths </p>
          <button className=" border-2 mt-12 border-[#3d5ea3] rounded-md p-2 w-2/3
           hover:bg-gradient-to-r from-[#3d5ea3]  hover:text-white text-[1.1rem] font-medium"  onClick={handleBookDemoClick}>
              Book Free Demo
            </button>
          {/* <div class="mt-10">
            <div class="grid mobiles:grid-cols-3 gap-4 items-center">
              <div class="flex flex-col items-center text-center">
                <h5 class="font-bold text-xl mb-1">10+</h5>
                <p>Years Experience</p>
              </div>
              <div class="flex flex-col items-center text-center">
                <h5 class="font-bold text-xl mb-1">890</h5>
                <p>Cases Solved</p>
              </div>
              <div class="flex flex-col items-center text-center">
                <h5 class="font-bold text-xl mb-1">250</h5>
                <p>Business Partners</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
       
      </section>
    </>
  );
};

export default Hero;
