import React, { useEffect } from "react";
import Glide from "@glidejs/glide";

export default function Slider() {
  const imageLinks = [
    "https://media.geeksforgeeks.org/wp-content/cdn-uploads/20210811183649/Top-7-Projects-in-Robotics-for-Beginners-and-Intermediates.png",
    "https://media.licdn.com/dms/image/D5612AQE03WW0tnU2wg/article-cover_image-shrink_600_2000/0/1675014214058?e=2147483647&v=beta&t=DWFkrwWKkhp_9r7joXXFXt7l7hclw3l8YZh2q9UWmWI",
    "https://premmohan.com/wp-content/uploads/2021/10/Robotics-Future.jpeg",
    "https://i.ytimg.com/vi/rBNzAGlSfnI/maxresdefault.jpg",
    "https://manavrachna.edu.in/wp-content/uploads/2023/09/AIML-Robotics-3.jpg",
  ];

  useEffect(() => {
    const slider = new Glide(".glide-02", {
      type: "slider",
      focusAt: "center",
      perView: 1,
      autoplay: 3500,
      animationDuration: 600,
      gap: 0,
      classes: {
        nav: {
          active: "[&>*]:bg-wuiSlate-700",
        },
      },
    }).mount();

    return () => {
      slider.destroy();
    };
  }, []);

  return (
    <>
      <div className="relative w-full h-full border-0 tablet:rounded-3xl border-red-900 glide-02">
        <div
          className="overflow-hidden h-full tablet:rounded-3xl"
          data-glide-el="track"
        >
          <ul className="whitespace-no-wrap h-full flex-no-wrap [backface-visibility: hidden] [transform-style: preserve-3d] [touch-action: pan-Y] [will-change: transform] relative flex w-full overflow-hidden p-0">
            {imageLinks.map((image, index) => (
              <li key={index}>
                <img
                  alt=""
                  src={image}
                  className="w-full max-w-full h-full m-auto"
                />
              </li>
            ))}
          </ul>
        </div>
        {/* indicator  */}
        <div
  className="flex absolute bottom-1 items-center justify-center w-full gap-2"
  data-glide-el="controls[nav]"
>
  {imageLinks.map((item, index) => {
    return(
    <button
      key={index}
      className="p-4 group"
      data-glide-dir={`=${index}`}
      aria-label={`goto slide ${index + 1}`}
    >
      <span
        className={`block w-8 h-2 transition-colors duration-300 rounded-full ring-1 ring-slate-700 focus:outline-none`}
      ></span>
    </button>
  )
  })}
</div>

      </div>
    </>
  );
}
