import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Card = ({image,title,grade,keypoints}) => {
  let displayText;
  let displayText2;

  if (grade === 'A') {
    displayText = 'Sarathi Basic';
    displayText2 = '3 - 5';
  } else if (grade === 'B') {
    displayText = 'Sarathi Intermediate';
    displayText2 = '6 - 8';
  } else if (grade === 'C') {
    displayText = 'Sarathi Advance';
    displayText2 = '9 - 12';
  } else {
    displayText = 'Unknown Grade';
  }
  

  return (
    <div className="rounded overflow-hidden shadow-lg flex flex-col h-[430px]"
    
    >
      <div>
        <div className="relative">
          <img
            className="w-full h-44 aspect-video"
            src={image}
            alt="Course Image"
          />
          <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
        </div>
      </div>

      <Link to="#">
        <div className="text-xs absolute top-0 right-0 bg-indigo-600 px-4 py-2 text-white mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
        </div>
      </Link>
      <span className="ml-1 font-extrabold bg-gray-100 rounded-b-lg w-full text-center"> {displayText} <span className="font-semibold">
       | Class: ({displayText2})</span> </span>

      <div className="px-6 py-4 mb-auto">
        <Link
          to="#"
          className="font-medium text-lg  hover:text-indigo-600 transition duration-500 ease-in-out inline-block mb-2"
        >
          {title}
        </Link>
        <p className="text-gray-500 text-sm">
            <ul className="custom-list2 flex gap-2 flex-wrap text-[0.8rem]">
              {keypoints.map((e)=>{
                return (
                  <>
                  <li>{e.points}</li>
                  </>
                )
              })}
            </ul>
        </p>
      </div>

      <div className="px-6 py-3 flex flex-row items-center justify-between bg-gray-100">
        <span className="py-1 text-xs font-regular text-gray-900 mr-1 flex flex-row items-center">
         
          
        </span>

        <span className="py-1 text-xs font-regular text-gray-900 mr-1 flex flex-row items-center">
        
          <button className="ml-1 text-orange-600 font-semibold">Join Now</button>
        </span>
      </div>
    </div>
  );
};

export default Card;
