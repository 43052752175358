const FaqData = [
    {
        "question": "What does Science-Sarathi offer?",
        "answer": "Science-Sarathi offers short-term courses, classroom programs, training programs, workshops, seminars, and assistance in setting up robotics labs."
      },
      {
        "question": "Why should I choose Science-Sarathi?",
        "answer": "There are several reasons to choose Science-Sarathi: 1. The team consists of experts from prestigious institutions like IITs, NITs, and other Institutes of National Importance. 2. Individual attention is given to every child through adaptive learning platforms. 3. The focus is on hands-on experiments rather than teaching theories. 4. Courses are designed for holistic child development leading to career-oriented growth. 5. Science-Sarathi provides technology-driven learning."
      },
      {
        "question": "What is Science-Sarathi's mission?",
        "answer": "Science-Sarathi's mission is multifaceted: 1. To spark curiosity by offering hands-on experiences in robotics. 2. To ensure inclusivity by allowing learners from diverse backgrounds to participate and benefit. 3. To cultivate critical skills such as collaboration, communication, and adaptability. 4. To mold future innovators and problem solvers."
      },
      {
        "question": "Who comprises the team at Science-Sarathi?",
        "answer": "The team at Science-Sarathi includes a diverse group of educators, technologists, and industry experts who are committed to reshaping the future of education."
      },
      {
        "question": "What age groups do Science-Sarathi programs cater to?",
        "answer": "Science-Sarathi programs cater to a wide range of age groups, from young children to adolescents."
      },
      {
        "question": "Are Science-Sarathi programs suitable for beginners?",
        "answer": "Yes, Science-Sarathi programs are designed to accommodate beginners and learners at all skill levels."
      },
      {
        "question": "How can I enroll in Science-Sarathi programs?",
        "answer": "You can enroll in Science-Sarathi programs by visiting our website or contacting us directly for more information on enrollment procedures."
      },
      {
        "question": "What kind of support is available for students during Science-Sarathi programs?",
        "answer": "Science-Sarathi offers various forms of support for students, including individual attention, access to expert educators, and assistance with any technical or conceptual difficulties."
      },
      {
        "question": "Are there any prerequisites for joining Science-Sarathi programs?",
        "answer": "There are typically no specific prerequisites for joining Science-Sarathi programs. However, some advanced courses may have recommended background knowledge in relevant subjects."
      },

]

module.exports = FaqData;