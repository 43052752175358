import React from "react";
import "./TopNav.css";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Logo from "../../Assets/logo science.png";
import Skillindia from '../../Assets/skilindia.png'
import Pmlogo from '../../Assets/pmlogo.png'
const TopNav = () => {
  return (
    <div className="border-0 bg-white border-green-800 w-full h-auto flex gap-2 mobilel:justify-evenly mobiles:justify-between  flex-wrap">
      <div className="logo mobilel:w-[450px]   h-[90px] flex  justify-center border-0 border-pink-800">
        <img
          src={Logo}
          alt=""
          className="mobiles:w-[90px] mobilel:w-[90px] p-2  border-0 border-black h-[90px]"
        />
        <div className="w-auto border-0 flex flex-col justify-center items-center border-black">
          <h1 className="text-[#f1ab49] text-3xl font-extrabold ">
            Science-Sarathi
          </h1>
          <h3 className="font-serif italic text-1xl">
            A Robotics Inovation Lab
          </h3>
        </div>
      </div>
      <div className="border-0 border-green-800 h-[90px] mobiles:flex-1 flex  ">
        <div className="border-0 border-red-800 h-full flex-1 flex gap-2 justify-between">
          <div className="w-3/4 border-0 tablet:flex flex-col justify-center items-center border-black mobiles:hidden " >
            <h1 className="text-[#55afd6]  fontcus font-sans   opacity-30">
              साइंस-सारथी
            </h1>
          </div>
          <div className="border-0 border-black  flex  bg-blend-color-burn ">
            <img className="h-full tablet:w-1/2 mobiles:w-full " src= {Skillindia} alt="" />
            <img className="h-full w-1/2 mobiles:hidden tablet:block" src= {Pmlogo} alt="" />
          </div>
        </div>
        <div className="border-0 border-black mobilel:w-1/4 laptop:w-1/4 h-full desktop:flex flex-col justify-center ml-4 mobiles:hidden ">
            <div className=" w-full  font-cera-medium border-0 border-green-800  tablet:flex flex-col justify-center items-start mobiles:hidden ">
            <div className="text-center">
              <CallIcon style={{ fontSize: "12px" }} />{": "}
              <a  href="tel:6202409257" className=" desktop:text-[15px] laptop:text-[12px] mobilem:text-[8px]">6294938013</a>,<a href="tel:6202409257" className="text-[15px]"> 9717707724</a>
            </div>
            <div className="text-center">
              <AlternateEmailIcon style={{ fontSize: "12px" }} />{" "}
              <a href="mailto:sciencesarathi1512@gmail.com" className="desktop:text-[15px] laptop:text-[12px] mobilem:text-[8px]">: sciencesarathi1512@gmail.com</a>
            </div>
          </div>
          <span className="p-2 font-cera-medium letter-spacing-2 desktop:text-[15px] laptop:text-[12px] mobilem:text-[8px] text-orange-600 font-semibold cursor-pointer">
            {" "}
            <EditNoteIcon /> Register For Free Sessions
          </span>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
