
// import Img from "../../Assets/coursesimg/programing1.jpg"

const Data1 = [
  // A 
  {
      "imagelink": "/assetsp/programing1.jpg",
      "title": "Coding and Programming Exploration",
      "grade": "A",
      "keypoints": [
        {"points": "Introduction to Coding"},
        {"points": "Block-Based Coding"},
        {"points": "Game Design Basics"}
      ]
    },
    {
      "imagelink": "/assetsp/robotics1.jpg",
      "title": "Robotics and Automation",
      "grade": "A",
      "keypoints": [
        // {"points": "Introduction to Motion and Structural Designing"},
        // {"points": "Real-life Mechanisms and Their Mechanical Counterparts"},
        {"points": "Basic Electrical & Electronics"},
        {"points": "Acknowledge Robotics"},
        {"points": "Sensing and Controlling"},
        {"points": "Scratch Based Programming"},
        // {"points": "Projects"}
      ]
    },
    
{
  "imagelink": "https://s3.ap-south-1.amazonaws.com/prod-gti/blog_image/6455005.jpg",
  "title": "IoT Innovation Fun",
  "grade": "A",
  "keypoints": [
    {"points": "Introduction to IoT"},
    {"points": "Exploring Simple Sensors"},
    {"points": "IoT and Everyday Objects"}
  ]
},
{
  "imagelink": "/assetsp/ai1.jpg",
  "title": "Artificial Intelligence and Machine Learning",
  "grade": "A",
  "keypoints": [
    {"points": "Introduction to AI and ML"},
    {"points": "AI in Everyday Life"},
    {"points": "Introduction to Coding for ML"}
  ]
},
    
    {
      "imagelink": "/assetsp/programing2.jpg",
      "title": "Coding and Programming Exploration",
      "grade": "B",
      "keypoints": [
        {"points": "Python Programming"},
        {"points": "Interactive Web Development"},
        {"points": "Introduction to JavaScript"}
      ]
    },
    {
      "imagelink": "/assetsp/robotics2.jpg",
      "title": "Robotics and Automation",
      "grade": "B",
      "keypoints": [
        {"points": "Introduction to Robotics"},
        {"points": "Basic Programming"},
        // {"points": "Sensors and Actuators"},
        {"points": "Arduino - Introduction to Microcontroller"},
        {"points": "Android App Development"},
        // {"points": "Projects"}
      ]
    },
    
    {
      "imagelink": "/assetsp/ai2.jpg",
      "title": "Artificial Intelligence and Machine Learning",
      "grade": "B",
      "keypoints": [
        {"points": "Hands-On with ML Tools"},
        {"points": "Introduction to Python for ML"},
        {"points": "Supervised Learning"}
      ]
    },
    {
      "imagelink": "https://media.licdn.com/dms/image/D4E12AQFXvIMNZQ0skw/article-cover_image-shrink_720_1280/0/1683796946201?e=2147483647&v=beta&t=dUUGiE0tVcMYrjMhrLUQQRscuiXeY6xgrNNLtEGYTMU",
      "title": "IoT Innovation Fun",
      "grade": "B",
      "keypoints": [
        {"points": "Introduction to Microcontrollers (Arduino/Raspberry Pi)"},
        {"points": "Creating a Smart Home Model"},
        {"points": "IoT Data Visualization"}
      ]
    },
    
    {
      "imagelink": "/assetsp/programing3.jpg",
      "title": "Coding and Programming Exploration",
      "grade": "C",
      "keypoints": [
        {"points": "Object-Oriented Programming (OOP)"},
        {"points": "Introduction to Algorithms"},
        {"points": "Game Development with Unity"}
      ]
    },
//--------------------



{
  "imagelink": "/assetsp/robotics3.jpg",
  "title": "Robotics and Automation",
  "grade": "C",
  "keypoints": [
    {"points": "Advance Programming Concepts"},
    // {"points": "Learn and Play with the Latest Sensors and Actuators"},
    // {"points": "Designing and Building Advance Robots"},
    {"points": "Robots Motion and Navigation"},
    {"points": "Artificial Intelligence"},
    {"points": "Application Based Projects"},
    // {"points": "Projects"}
  ]
},
//--------------------------------





{
  "imagelink": "https://www.digi.com/getattachment/blog/post/12-fun-facts-about-iot-2021/gettyimages-1215247403-1280x720.jpg?lang=en-us&width=1280&height=720&ext=.jpg",
  "title": "IoT Innovation Fun",
  "grade": "C",
  "keypoints": [
    {"points": "Advanced Sensors and Actuators"},
    {"points": "Cloud Computing for IoT"},
    {"points": "IoT Security"}
  ]
},

//---------------------------------------






{
  "imagelink": "/assetsp/ai3.jpg",
  "title": "Artificial Intelligence and Machine Learning",
  "grade": "C",
  "keypoints": [
    {"points": "Introduction to Neural Networks"},
    {"points": "Unsupervised Learning"},
    {"points": "AI Ethics and Bias"}
  ]
},
]

  module.exports = Data1;