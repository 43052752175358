import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'
const Navbar = () => {

    const [isToggleOpen, setIsToggleOpen] = useState(false);
    const [active,setActive] = useState("home");
    
    return (
      <>
        {/*<!-- Basic Navbar --> */}
        <header className="border-b-1 relative z-20 w-full border-b border-slate-200 bg-[#3d5ea3] shadow-lg shadow-slate-700/5 after:absolute after:top-full after:left-0 after:z-10 after:block after:h-px after:w-full after:bg-slate-200 tablet:border-slate-200 tablet:backdrop-blur-sm tablet:after:hidden">
          <div className="relative mx-auto max-w-full px-6 tablet:max-w-5xl laptop:max-w-7xl">
            <nav
              aria-label="main navigation"
              className={`flex h-[3.5rem]  justify-between tablet:justify-around  items-center font-medium text-white`}
              role="navigation"
            >
              {/*      <!-- Mobile trigger --> */}
              <button
                className={`relative order-10 block h-10 w-10 self-center tablet:hidden
                  ${
                    isToggleOpen
                      ? "visible opacity-100 [&_span:nth-child(1)]:w-6 [&_span:nth-child(1)]:translate-y-0 [&_span:nth-child(1)]:rotate-45 [&_span:nth-child(3)]:w-0 [&_span:nth-child(2)]:-rotate-45 "
                      : ""
                  }
                `}
                onClick={() => setIsToggleOpen(!isToggleOpen)}
                aria-expanded={isToggleOpen ? true : false}
                aria-label="Toggle navigation"
              >
                <div className="absolute top-1/2 left-1/2 w-6 -translate-x-1/2 -translate-y-1/2 transform">
                  <span
                    aria-hidden="true"
                    className="absolute block h-0.5 w-9/12 -translate-y-2 transform rounded-full bg-slate-900 transition-all duration-300"
                  ></span>
                  <span
                    aria-hidden="true"
                    className="absolute block h-0.5 w-6 transform rounded-full bg-slate-900 transition duration-300"
                  ></span>
                  <span
                    aria-hidden="true"
                    className="absolute block h-0.5 w-1/2 origin-top-left translate-y-2 transform rounded-full bg-slate-900 transition-all duration-300"
                  ></span>
                </div>
              </button>
              {/*      <!-- Navigation links --> */}
              <ul
                role="menubar"
                aria-label="Select page"
                className={` border-0 border-black absolute top-0 left-0 z-[-1] h-[28.5rem] w-full justify-center overflow-hidden  overflow-y-auto overscroll-contain bg-[#1a337f] px-8 pb-12 pt-24 font-medium transition-[opacity,visibility] duration-300 tablet:visible tablet:relative tablet:top-0  tablet:z-0 tablet:flex tablet:flex-row tablet:h-full tablet:w-auto tablet:items-stretch tablet:overflow-visible tablet:bg-white/0 tablet:px-0 tablet:py-0  tablet:pt-0 tablet:opacity-100 mobiles:flex-col ${
                  isToggleOpen
                    ? "visible opacity-100 backdrop-blur-sm"
                    : "invisible opacity-0"
                }`}
              >
                <li role="none" className="flex items-stretch">
                  <Link
                    role="menuitem"
                    aria-haspopup="false"
                    className={`flex items-center gap-2  py-4 transition-colors duration-300 hover:text-orange-500 focus:text-orange-600 focus:outline-none focus-visible:outline-none tablet:px-8 ${active==="home"?"text-orange-600":"text-white"}`}
                    to="/"
                    onClick={() => {
                      setIsToggleOpen(!isToggleOpen);
                      setActive("home");
                    }}
                    
                  >
                    <span  className="hover-underline-animation" >Home</span>
                  </Link>
                </li>
                <li role="none" className="flex items-stretch">
                  <Link
                    role="menuitem"
                    aria-current="page"
                    aria-haspopup="false"
                    className={`flex items-center gap-2  py-4 hover:text-orange-500 transition-colors duration-300  focus:text-orange-600 focus:outline-none
                    focus-visible:outline-none tablet:px-8  ${active==="about"?"text-orange-600":"text-white"}`}
                    to="/about"
                    onClick={()=>{setActive("about");
                    setIsToggleOpen(!isToggleOpen);}}
                  >
                    <span className="hover-underline-animation" >About Us</span>
                  </Link>
                </li>
                <li role="none" className="flex items-stretch">
                  <Link
                    role="menuitem"
                    aria-haspopup="false"
                    className={`flex items-center gap-2  py-4 transition-colors duration-300 hover:text-orange-500 focus:text-orange-600 focus:outline-none 
                    focus-visible:outline-none tablet:px-8 ${active==="courses"?"text-orange-600":"text-white"}`}
                    to="/courses"
                    onClick={()=>{setActive("courses");
                    setIsToggleOpen(!isToggleOpen);}}
                  >
                    <span className="hover-underline-animation" >Courses</span>
                  </Link>
                </li>
                <li role="none" className="flex items-stretch">
                  <Link
                    role="menuitem"
                    aria-haspopup="false"
                    className={`flex items-center gap-2  py-4 transition-colors duration-300 hover:text-orange-500 focus:text-orange-600 focus:outline-none 
                    focus-visible:outline-none tablet:px-8 ${active==="seminar"?"text-orange-600":"text-white"}`}
                    to="/workshop"
                    onClick={()=>{setActive("seminar");
                    setIsToggleOpen(!isToggleOpen);}}
                  >
                    <span className="hover-underline-animation" >Workshop/Seminar</span>
                  </Link>
                </li>
                <li role="none" className="flex items-stretch">
                  <Link
                    role="menuitem"
                    aria-haspopup="false"
                    className={`flex items-center gap-2  py-4 transition-colors duration-300 hover:text-orange-500 focus:text-orange-600 focus:outline-none 
                    focus-visible:outline-none tablet:px-8 ${active==="lab"?"text-orange-600":"text-white"}`}
                    to="/lab"
                    onClick={()=>{setActive("lab");
                    setIsToggleOpen(!isToggleOpen);}}
                  >
                    <span className="hover-underline-animation" >Lab Setup</span>
                  </Link>
                </li>
                <li role="none" className="flex items-stretch">
                  <Link
                    role="menuitem"
                    aria-haspopup="false"
                    className={`flex items-center gap-2  py-4 transition-colors duration-300 hover:text-orange-500 focus:text-orange-600 focus:outline-none 
                    focus-visible:outline-none tablet:px-8 ${active==="gal"?"text-orange-600":"text-white"}`}
                    to="/gallery"
                    onClick={()=>{setActive("gal");
                    setIsToggleOpen(!isToggleOpen);}}
                  >
                    <span className="hover-underline-animation" >Gallery</span>
                  </Link>
                </li>
                <li role="none" className="flex items-stretch">
                  <Link
                    role="menuitem"
                    aria-haspopup="false"
                    className={`flex items-center gap-2  py-4 transition-colors duration-300 hover:text-orange-500 focus:text-orange-600 focus:outline-none 
                    focus-visible:outline-none tablet:px-8 ${active==="contact"?"text-orange-600":"text-white"}`}
                    to="/contact"
                    onClick={()=>{setActive("contact");
                    setIsToggleOpen(!isToggleOpen);}}
                  >
                    <span className="hover-underline-animation" >Contact</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        {/*<!-- End Basic Navbar--> */}
      </>
    );
};

export default Navbar