import React, { useEffect } from "react";
import Select from "react-select";
import Card from "./Card"
import Data1 from "../Json Data/Data1";
const Allcourses = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  const options1 = [
    { value: "6", label: "Calss 6" },
    { value: "7", label: "Calss 7" },
    { value: "8", label: "Calss 8" },
    { value: "9", label: "Calss 9" },
    { value: "10", label: "Calss 10" },
    { value: "11", label: "Calss 11" },
    { value: "12", label: "Calss 12" },
  ];
  const options2 = [
    { value: "A", label: "Coding and Programming" },
    { value: "B", label: "Robotics and Automation" },
    { value: "C", label: "IoT Innovation Fun" },
    { value: "D", label: "A.I & M.L" }
  ];
  return (
    <div className="border-0 tablet:w-[80%] ml-auto mr-auto border-red-600 flex flex-col justify-start gap-6  items-center h-auto">
      <div className="flex justify-between p-5 w-full">
        <div className="text-[20px] font-medium">LIVE Classes</div>
        <div  className="flex gpa-4 w-auto justify-evenly border-0 border-black">
            <Select options={options1} placeholder="All Class" />
            <Select options={options2} placeholder="All Subjects" />
        </div>
      </div>

      <br />
    
    <div className=" flex gap-6 flex-wrap justify-center">
      
        {Data1.map((item)=>{
            return(
              <>
              <div className="w-96">
               <Card image = {item.imagelink} title={item.title}  grade={item.grade} keypoints={item.keypoints}/>
               </div>
              </>
               
            )
        })}
        
    </div>

    <br /><br /><br /><br />

    </div>
  );
};

export default Allcourses;
