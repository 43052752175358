import React from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import { useTranslation } from "react-i18next";
const Update = ({ items, lang, align }) => {
  // const { t, i18n } = useTranslation();
  // // i18n.changeLanguage(lang);
  return (
    <div
      className={`border-0 border-black tablet:w-5/12  flex  ${
        align === "left" ? "justify-end" : "justify-start"
      }`}
    >
      <div className="border-2 h-[400px] w-[480px] rounded-lg border-gray-400 flex flex-col">
        <h1 className="bg-[#627bc4] p-5 rounded-t-md text-white text-left flex  justify-start gap-2 items-center">
          {" "}
          <StarBorderIcon style={{ color: "white" }} />{" "}
          {/* <span className=" text-red-900 text-[25px] blinking-text font-extrabold">&#9734;</span> */}
          {lang === "en" ? (
            <span className="text-[20px] font-bold">What's New
              </span>
          ) : (
            <span className="text-[20px] font-bold">नया क्या है?
            </span>
          )}
        </h1>

        <ul className="list-none p-0 overflow-y-auto custom-scrollbar">
          {items.length > 0 ? (
            items.map((item, index) => (
              <React.Fragment key={index}>
                <li
                  className={`p-3 ${
                    index % 2 === 0 ? "bg-sky-100" : "bg-white"
                  }`}
                >
                  <p className="">
                    <CalendarMonthIcon style={{ fontSize: "15px" }} /> :{" "}
                    {item.date}
                  </p>
                  <p className="text-justify"> {item.content} </p>
                </li>
                <hr className="w-11/12 ml-auto mr-auto border-b-[1px] border-gray-200" />
              </React.Fragment>
            ))
          ) : (
            <>
              {lang === "en" ? (
                <p className="text-center items-center">
                  No announcements for now.
                </p>
              ) : (
                <p className="text-center items-center">अभी कोई घोषणा नहीं है।</p>
              )}
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Update;
