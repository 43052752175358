import React, { useEffect } from "react";
import "./Contact.css";
import AddIcCallOutlinedIcon from "@mui/icons-material/AddIcCallOutlined";
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { TextField, TextareaAutosize } from "@mui/material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AOS from "aos";
import "aos/dist/aos.css";

const Contact = () => {

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
      easing: "ease-in-out",
    });
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  return (
    <div className="h-auto border-0 border-red-600  roboto">
      <section className="border-0  border-black w-full h-[400px] flex flex-col justify-center items-center  background-contact">
        <h1 className="tablet:text-[62px] mobiles:text-[45px] text-center tablet:text-black mobiles:text-white  -mt-28">
          Feel free To <span className="textcurve">Contact Us</span> 
        </h1>
        <h2 className="p-3 tablet:text-[25px] mobiles:text-[20px] text-center tablet:text-black mobiles:text-white">Let us know how we can help.</h2>
      </section>
      <section className="border-0 border-yellow-400 w-full h-auto -mt-14 flex flex-col justify-start items-center upper-shadow">
        <div className="border-0 border-red-700 -mt-24 tablet:w-[70vw] mobiles:w-[90%] flex justify-evenly flex-wrap gap-4 h-auto">
          {/* contact card  */}
          <div className="tablet:w-[240px] mobiles:w-[200px] h-[200px] border-2 flex flex-col gap-2 justify-between  bg-white rounded-xl shadow-2xl shadow-slate-100 border-gray-200">
            <div className="icon  w-full h-14 flex justify-start p-2">
              <div className="w-[30px] h-[30px] rounded-md border-0 border-red-500">
                {" "}
                <AddIcCallOutlinedIcon style={{ color: "gray" }} />{" "}
              </div>{" "}
            </div>
            <div className="text flex justify-center pl-2 flex-col">
              <h2 className="text-1xl font-medium p-2">Call us On: </h2>
              <p className="pl-2"> Mon-Fri 8AM-5PM</p>
            </div>
            <p className="p-4 font-semibold underline">+91 9717707724</p>
          </div>
          {/* contact card end  */}
          {/* contact card  */}
          <div className="tablet:w-[240px] mobiles:w-[200px] h-[200px] border-2 flex flex-col gap-2 justify-between  bg-white rounded-xl shadow-2xl shadow-slate-100 border-gray-200">
            <div className="icon  w-full h-14 flex justify-start p-2">
              <div className="w-[30px] h-[30px] rounded-md border-0 border-red-500">
                {" "}
                <WhatsAppIcon style={{ color: "gray" }} />{" "}
              </div>{" "}
            </div>
            <div className="text flex justify-center pl-2 flex-col">
              <h2 className="text-1xl font-medium p-2">Chat with us: </h2>
              <p className="pl-2"> </p>
            </div>
            <p className="p-4 font-semibold underline">+91 9717707724</p>
          </div>
          {/* contact card end  */}
          {/* contact card  */}
          <div className="tablet:w-[240px] mobiles:w-[200px] h-[200px] border-2 flex flex-col gap-2 justify-between  bg-white rounded-xl shadow-2xl shadow-slate-100 border-gray-200">
            <div className="icon  w-full h-14 flex justify-start p-2">
              <div className="w-[30px] h-[30px] rounded-md border-0 border-red-500">
                {" "}
                <MarkEmailReadOutlinedIcon style={{ color: "gray" }} />{" "}
              </div>{" "}
            </div>
            <div className="text flex justify-center pl-2 flex-col">
              <h2 className="text-1xl font-medium p-2">E-Mail at: </h2>
              <p className="pl-2"> </p>
            </div>
            <p className="p-2 font-semibold mobiles:text-[.8rem] tablet:text-[1rem] underline">sciencesarathi1512@gmail.com</p>
          </div>
          {/* contact card end  */}
          {/* contact card  */}
          <div className="tablet:w-[240px] mobiles:w-[200px] h-[200px] border-2 flex flex-col gap-2 justify-between  bg-white rounded-xl shadow-2xl shadow-slate-100 border-gray-200">
            <div className="icon  w-full h-14 flex justify-start p-2">
              <div className="w-[30px] h-[30px] rounded-md border-0 border-red-500">
                {" "}
                <LinkedInIcon style={{ color: "gray" }} />{" "}
              </div>{" "}
            </div>
            <div className="text flex justify-center pl-2 flex-col">
              <h2 className="text-1xl font-medium p-2">Connect with us: </h2>
              <p className="pl-2"> On Linked In</p>
            </div>
            <p className="p-4 font-semibold underline">Science Sarathi</p>
          </div>
          {/* contact card end  */}
          
        </div>
      </section>

      {/* map  */}

      <section className="border-0 p-6 border-red-800 w-full h-auto" data-aos="fade-down"
     data-aos-duration="1000">
        <h1 className="text-center tablet:text-5xl mobiles:text-3xl tablet:p-6 mobiles:pt-8 mobiles:mt-4  font-semibold ">
          Contact at office
        </h1>
        <div className="border-0 p-6 border-black h-auto flex flex-wrap gap-6 justify-center items-center">
          <div className="tablet:w-[750px] mobiles:w-full border-0 flex flex-col justify-center border-red-600 tablet:h-[60vh] mobiles:h-[25vh]">
            <h1 className="text-4xl font-mediumm text-center p-2 ">
              Headquater Address:{" "}
            </h1>
            <address className="text-2xl text-center p-2">
              Tigga Complex, Harmu Road, Alkapuri, <br />
              Daud Nagar, Ranchi, Jharkhand-834002
            </address>
          </div>
          <div className="tablet:w-[550px] mobiles:w-full border-0 border-red-600 tablet:h-[55vh] mobiles:h-[40vh]">
            <iframe
              className="shadow-2xl shadow-slate-400 rounded-lg"
              title="map location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11700.977330847061!2d85.29386622364781!3d23.3345241212653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f4e14bd91c2547%3A0x3d3115c462e3553a!2sTigga%20complex!5e0!3m2!1sen!2sin!4v1702139864293!5m2!1sen!2sin"
              width="100%"
              height="100%"
              allowfullscreen="true"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
      {/* map end  */}

      {/* Contact form start  */}
      <section className="background-chat">
      <div class="font-[sans-serif] max-w-full mx-auto relative bg-white/40 py-6  shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] overflow-hidden">
            {/* <div class="absolute -top-6 -left-6 w-20 h-20 rounded-full bg-blue-400"></div>
            <div class="absolute -bottom-6 -left-0 w-24 h-20 rounded-tr-[40px] bg-teal-200"></div>
            <div class="absolute -top-6 -right-6 w-20 h-20 rounded-full bg-blue-400"></div>
            <div class="absolute -bottom-6 -right-0 w-24 h-20 rounded-tl-[40px] bg-blue-300"></div> */}
            <div class="grid tablet:grid-cols-2">
                <div class="text-center p-6 tablet:p-10 flex flex-col items-center justify-center">
                    <h2 class="text-3xl text-[#1e337f] font-bold">Contact Us</h2>
                    <img src="https://readymadeui.com/contact.webp" class="mt-4 shrink-0 w-full" alt="" />
                </div>
                <form class="p-6 tablet:p-10">
                  
                    <div class="max-w-sm mx-auto space-y-4">
                        <input type='text' placeholder='Name'
                            class="w-full bg-gray-100 rounded-full py-3 px-6 text-sm outline-none" />
                        <input type='email' placeholder='Email'
                            class="w-full bg-gray-100 rounded-full py-3 px-6 text-sm outline-none" />
                        <input type='email' placeholder='Phone No.'
                            class="w-full bg-gray-100 rounded-full py-3 px-6 text-sm outline-none" />
                        <textarea placeholder='Message' rows="6"
                            class="w-full bg-gray-100 rounded-3xl px-6 text-sm pt-3 outline-none"></textarea>
                        <button type='button'
                            class="text-white w-full relative bg-blue-500 hover:bg-blue-600 font-semibold rounded-full text-sm px-6 py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill='#fff' class="mr-2 inline" viewBox="0 0 548.244 548.244">
                                <path fill-rule="evenodd" d="M392.19 156.054 211.268 281.667 22.032 218.58C8.823 214.168-.076 201.775 0 187.852c.077-13.923 9.078-26.24 22.338-30.498L506.15 1.549c11.5-3.697 24.123-.663 32.666 7.88 8.542 8.543 11.577 21.165 7.879 32.666L390.89 525.906c-4.258 13.26-16.575 22.261-30.498 22.338-13.923.076-26.316-8.823-30.728-22.032l-63.393-190.153z" clip-rule="evenodd" data-original="#000000" />
                            </svg>
                            Send Message
                        </button>
                    </div>
                </form>
            </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
