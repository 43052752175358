import React from 'react'
import TopNav from '../TopNav/TopNav'
import Navbar from '../Navbar/Navbar'
import { Outlet } from 'react-router-dom'
import Footer from '../Footer/Footer'
const Layout = () => {
  return (
    // full layout
    <>
  
    <div className='h-auto  mobiles:w-[490px] tablet:w-full border-0 border-red-500'> 
    <div className="sticky top-0 z-50">
    <TopNav/>
    <Navbar/>
    </div>
    <div className='min-h-screen'>
      <Outlet/>
    </div>
    <Footer/>
    
    </div>

    </>
  )
}

export default Layout


