import React, { useState, useEffect } from "react";
import DemoImg from "../../Assets/demo.png";
import Select from "react-select";
import { Link } from "react-router-dom";
import axios from "axios";
import img11 from "../../Assets/img11.jpg"

const Seminar = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    school: "",
    fathername: "",
    email: "",
    class: "",
    gender: "",
    termsAccepted: false,
  });

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [sendLoader, setSendLoader] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
    // Clear error message for the field being modified
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!formData.fathername.trim()) {
      errors.fathername = "Father's Name is required";
      isValid = false;
    }

    if (!formData.school.trim()) {
      errors.school = "School Name & Address is required";
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = "Email/Mobile is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
      isValid = false;
    }

    if (!formData.class) {
      errors.class = "Class is required";
      isValid = false;
    }

    if (!formData.gender) {
      errors.gender = "Gender is required";
      isValid = false;
    }

    if (!formData.termsAccepted) {
      errors.termsAccepted = "You must accept the Terms and Conditions";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const createPost = async (name, school, email, selectedClass, gender, termsAccepted, fathername) => {
    try {
      const result = await axios.post(
        "http://localhost:5500/mail",
        {
          name,
          email
        }
      );
      console.log(result);
      // alert("Dome")
      setShowConfirmation(true);
      setFormData({
        name: "",
        school: "",
        fathername: "",
        email: "",
        class: "",
        gender: "",
        termsAccepted: false,
      });
      setSendLoader(false)
    } catch (e) {
      alert("Some network error please try again");
      setSendLoader(false)
    }
  };


  const handleSubmit = () => {
    
    if (validateForm()) {
      // Proceed with form submission
      const { name, school, email, class: selectedClass, gender, termsAccepted, fathername } = formData;
      const mess = `Name: ${name}\n Father's Name: ${fathername}\nSchool: ${school}\nEmail/Mobile: ${email}\nClass: ${selectedClass}\nGender: ${gender}\nTerms Accepted: ${termsAccepted ? "Yes" : "No"}`;
      setSendLoader(true)
      createPost(name, school, email, selectedClass, gender, termsAccepted, fathername);
      console.log(mess)
    }
  };

  const options1 = [
    { value: "6", label: "Class 6" },
    { value: "7", label: "Class 7" },
    { value: "8", label: "Class 8" },
    { value: "9", label: "Class 9" },
    { value: "10", label: "Class 10" },
    { value: "11", label: "Class 11" },
    { value: "12", label: "Class 12" },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#f3f4f6",
    }),
  };

  return (
    <>
      <section className="background-chat">
        <div className="font-[sans-serif] max-w-full mx-auto relative bg-white/40 py-6 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] overflow-hidden">
          <div className="absolute -top-6 -left-6 w-32 h-32 rounded-full bg-[#1e337f]/20"></div>
          <div className="absolute -bottom-6 -left-0 w-20 h-20 rounded-tr-[40px] bg-teal-200/40"></div>
          <div className="absolute -top-6 -right-6 w-40 h-40 -z-2 rounded-full bg-[#1e337f]/20"></div>
          <div className="absolute -bottom-6 -right-0 w-24 h-20 rounded-tl-[40px] bg-blue-300"></div>
          <div className="grid tablet:grid-cols-2">
            <div className="text-center p-6 tablet:p-10 flex flex-col items-center justify-center">
              <img
                src={DemoImg}
                alt=""
                className="mt-4 shrink-0 w-4/5 border-0 border-red-900 h-4/5"
              />
            </div>
            <form className="p-6 tablet:p-10 desktop:w-4/6 laptop:w-3/4 mx-auto  bg-white">
              <div className="space-y-2">
                <input
                  name="name"
                  type="text"
                  className="bg-gray-100 w-full text-sm px-4 py-4 focus:bg-transparent outline-orange-300 transition-all"
                  placeholder="Enter name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                {errors.name && <p className="text-red-500  w-full  text-xs mt-1">{errors.name}</p>}
                <input
                  name="fathername"
                  type="text"
                  className="bg-gray-100 w-full text-sm px-4 py-4 focus:bg-transparent outline-orange-300 transition-all"
                  placeholder="Father's Name"
                  value={formData.fathername}
                  onChange={handleChange}
                  required
                />
                {errors.fathername && <p className="text-red-500  w-full text-xs mt-1">{errors.fathername}</p>}
                <input
                  name="school"
                  type="text"
                  className="bg-gray-100 w-full text-sm px-4 py-4 focus:bg-transparent outline-orange-300 transition-all"
                  placeholder="School Name & Address"
                  value={formData.school}
                  onChange={handleChange}
                  required
                />
                {errors.school && <p className="text-red-500  w-full text-xs mt-1">{errors.school}</p>}
                <input
                  name="email"
                  type="text"
                  className="bg-gray-100 w-full text-sm px-4 py-4 focus:bg-transparent outline-orange-300 transition-all"
                  placeholder="Enter email / Mobile No"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && <p className="text-red-500  w-full text-xs mt-1">{errors.email}</p>}
                <h3 className="mb-4 font-semibold text-gray-500">Class</h3>
                <Select
                  styles={customStyles}
                  options={options1}
                  placeholder="All Class"
                  onChange={(selectedOption) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      class: selectedOption.value,
                    }))
                  }
                />
                <h3 className="mb-4 font-semibold text-gray-500">Gender</h3>
                <ul className="items-center bg-gray-100 w-full text-sm font-medium text-gray-900  border border-gray-200 rounded-lg mobiles:flex  ">
                  <li className="w-full border-b border-gray-200 mobiles:border-b-0  dark:border-gray-600">
                    <div className="flex items-center ps-3">
                      <input
                        id="male"
                        type="radio"
                        value="Male"
                        name="gender"
                        className="w-4 h-4 text-blue-600 bg-gray-100  border-gray-300 focus:ring-blue-500  focus:ring-2"
                        onChange={handleChange}
                        checked={formData.gender === "Male"}
                      />

                      <label
                        htmlFor="male"
                        className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
                      >
                        Male{" "}
                      </label>
                      <input
                        id="female"
                        type="radio"
                        value="female"
                        name="gender"
                        className="w-4 h-4 text-blue-600 bg-gray-100  border-gray-300 focus:ring-blue-500  focus:ring-2"
                        onChange={handleChange}
                        checked={formData.gender === "female"}
                      />
                      <label
                        htmlFor="female"
                        className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
                      >
                        Female{" "}
                      </label>
                      <input
                        id="others"
                        type="radio"
                        value="others"
                        name="gender"
                        className="w-4 h-4 text-blue-600 bg-gray-100  border-gray-300 focus:ring-blue-500  focus:ring-2"
                        onChange={handleChange}
                        checked={formData.gender === "others"}
                      />
                      <label
                        htmlFor="others"
                        className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
                      >
                        Others{" "}
                      </label>
                    </div>
                    {errors.gender && <p className="text-red-500  w-full text-xs mt-1">{errors.gender}</p>}
                  </li>
                  {/* Similar radio button elements for Female and Other */}
                </ul>
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="termsAccepted"
                    type="checkbox"
                    className="h-4 w-4 shrink-0 border-gray-300 rounded"
                    checked={formData.termsAccepted}
                    onChange={handleChange}
                  />
                  <label htmlFor="remember-me" className="ml-3 block text-sm">
                    I accept the{" "}
                    <Link
                      to="/"
                      className="text-orange-400 font-semibold hover:underline ml-1"
                    >
                      Terms and Conditions
                    </Link>
                  </label>
                </div>
                {errors.termsAccepted && <p className="text-red-500  w-full text-xs mt-1">{errors.termsAccepted}</p>}
              </div>
              <div className="mt-8">
                <button
                  type="button"
                  className="w-full py-4 px-4 text-sm font-semibold flex justify-center gap-3 text-white bg-orange-400 hover:bg-orange-500 focus:outline-none"
                  onClick={handleSubmit}
                >
                  <span>Request a Call Back</span>

                  {sendLoader && <svg aria-hidden="true" class="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>}

                  
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      {showConfirmation && (
        <div className="fixed inset-0 px-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
          <div className="w-full max-w-md bg-white shadow-lg rounded-md px-5 py-10 relative mx-auto text-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-24 h-24 fill-green-300 absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2" viewBox="0 0 60 60">
              <circle cx="30" cy="30" r="29" data-original="#5edd60" />
              <path fill="#fff" d="m24.262 42.07-6.8-6.642a1.534 1.534 0 0 1 0-2.2l2.255-2.2a1.621 1.621 0 0 1 2.256 0l4.048 3.957 11.353-17.26a1.617 1.617 0 0 1 2.2-.468l2.684 1.686a1.537 1.537 0 0 1 .479 2.154L29.294 41.541a3.3 3.3 0 0 1-5.032.529z" data-original="#ffffff" />
            </svg>
            <div className="mt-8">
              <h3 className="text-2xl font-semibold flex-1">Awesome!</h3>
              <p className="text-sm text-gray-500 mt-2"></p>
              <button type="button"
                className="px-6 py-2.5 mt-8 w-full rounded text-white text-sm font-semibold border-none outline-none bg-green-300 hover:bg-green-400"
                onClick={() => setShowConfirmation(false)}
              >
                Got it
              </button>
            </div>
          </div>
        </div>
      )}

<div className="font-[sans-serif]">
      <div className="relative overflow-hidden">
        <div className="max-w-screen-xl mx-auto py-16 px-4 mobiles:px-6 laptop:py-32 laptop:px-8">
          <div className="relative z-10 text-center laptop:text-left">
            <h1 className="text-2xl tracking-tight leading-10  font-extrabold text-black mobiles:text-3xl mobiles:leading-none tablet:text-4xl laptop:text-5xl">
              Welcome to
              <br className="desktopx:hidden" />
              <span className="text-black"> Science-Sarathi STEAM Workshop</span>
            </h1>
            <p className="mt-4 max-w-md mx-auto text-lg text-[#1a2b4f] tablet:bg-white/50 mobiles:text-xl tablet:mt-5 tablet:max-w-3xl">
            Igniting curiosity, fostering creativity, and equipping students with essential 21st-century skills through dynamic STEAM workshops.
            </p>
            <div className="mt-12 mobiles:flex mobiles:justify-center laptop:justify-start">
              <div className="rounded-md shadow">
                <button className="w-full flex items-center justify-center px-8 py-3 text-base font-semibold rounded-md text-indigo-600 bg-white hover:text-[#3d5ea3] hover:bg-indigo-100 transition duration-150 ease-in-out">
                  Get Started
                </button>
              </div>
              <div className="mt-3 mobiles:mt-0 mobiles:ml-4">
                <button className="w-full flex items-center justify-center px-8 py-3 text-base font-semibold rounded-md text-white bg-indigo-500 hover:bg-[#3d5ea3] transition duration-150 ease-in-out">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="laptop:absolute laptop:inset-y-0 laptop:right-0 laptop:w-1/2 flex flex-col justify-center">
          <img className="h-56 w-[80%] m-auto items-center object-cover mobiles:h-72 tablet:h-96 laptop:w-[80%] rounded-3xl laptop:h-[80%]" src={img11} alt="Delicious Food" />
        </div>
      </div>
    </div>
    </>
  );
};

export default Seminar;
