import { Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './conponents/Layout/Layout';
import Home from './conponents/Home/Home';
import About from './conponents/About/About';
import Courses from './conponents/Courses/Courses';
import Seminar from './conponents/Seminar/Seminar';
import Lab from './conponents/Lab/Lab';
import Contact from './conponents/Contact/Contact';
import Item from './conponents/Courses/Item';
import Allcourses from './conponents/Courses/Allcourses';
import Gallery from './conponents/Seminar/Gallery';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"
function App() {
  return (
    <>
    <Analytics/>
    <SpeedInsights/>
    <Routes>
      <Route path='/' element={<Layout/>}>
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/courses' element={<Courses/>}/>
      <Route path='/courses/item' element={<Item/>}/>
      <Route path='/courses/item/all' element={<Allcourses/>}/>
      <Route path='/workshop' element={<Seminar/>}/>
      <Route path='/lab' element={<Lab/>}/>
      <Route path='/gallery' element={<Gallery/>}/>
      <Route path='/contact' element={<Contact/>}/>
      </Route>
    </Routes>

    
    </>
  );
}
export default App;
