import React from 'react'
import CarouselIndicatorsControlsInside from '../Courses/CarouselControlsInside'
import Faq from '../About/Faq'


const Lab = () => {
  return (
    <div className='w-[80%]'>
      <h1 className="text-4xl">Comming Soon...........</h1>
    </div>
  )
}

export default Lab