import React, { useEffect, useState } from "react"
import Glide from "@glidejs/glide"
import Card from "./Card"
import Data1 from "../Json Data/Data1"
import AOS from "aos";
import "aos/dist/aos.css";
export default function CarouselIndicatorsControlsInside({selected}) {

  const [gradeSelected,setGradeSelected] = useState("");
console.log("from crousel", selected)

useEffect(()=>{
    if(selected == 3 || selected == 4 || selected == 5){
    setGradeSelected("A")
  }
  else if(selected == 6 || selected == 7 || selected == 8){
    setGradeSelected("B")
  }
  else if(selected == 9 || selected == 10 || selected == 11 || selected == 12){
    setGradeSelected("C")
  }
})


  console.log("grade ",gradeSelected)
  const filteredData = gradeSelected ? Data1.filter(item => item.grade === gradeSelected) : Data1;

  console.log(filteredData)

  useEffect(() => {
    const slider = new Glide(".glide-03", {
      type: "carousel",
      focusAt: "center",
      perView: 3,
      autoplay: 3000,
      animationDuration: 700,
      gap: 24,
      classNames: {
        nav: {
          active: "[&>*]:bg-wuiSlate-700",
        },
      },
      breakpoints: {
        1024: {
          perView: 2,
        },
        640: {
          perView: 1,
        },
      },
    }).mount()

    return () => {
      slider.destroy()
    }
  }, [])

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
      easing: "ease-in-out",
    });

  }, []);

  return (
    <>
      {/*<!-- Component: Carousel with indicators & controls inside --> */}
      <div className="glide-03 relative w-full rounded-2xl" 
      // data-aos="zoom-in"
      >
        {/*    <!-- Slides --> */}
        <div className="overflow-hidden rounded-2xl" data-glide-el="track">
          <ul className="whitespace-no-wrap rounded-2xl flex-no-wrap [backface-visibility: hidden] [transform-style: preserve-3d] [touch-action: pan-Y] [will-change: transform] relative flex w-full overflow-hidden p-0">
          
          {filteredData.map((item)=>{
            return(
              <li
              // data-aos="zoom-in"
              >
              <span className="m-auto rounded-3xl max-h-full w-full max-w-full">
                <Card image = {item.imagelink} title={item.title}  grade={item.grade} keypoints={item.keypoints} />
              </span>
            </li>
            )
          })}
          </ul>
        </div>
        {/*    <!-- Controls --> */}
        <div
          className="absolute left-0 top-1/2 flex h-0 w-full items-center justify-between px-4 "
          data-glide-el="controls"
        >
          <button
            className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-slate-700 bg-blue-500/60 text-slate-700 transition duration-300 hover:border-slate-900 hover:text-slate-900 focus-visible:outline-none lg:h-12 lg:w-12"
            data-glide-dir="<"
            aria-label="prev slide"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-5 w-5"
            >
              <title>prev slide</title>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              />
            </svg>
          </button>
          <button
            className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-slate-700 bg-blue-500/60 text-slate-700 transition duration-300 hover:border-slate-900 hover:text-slate-900 focus-visible:outline-none lg:h-12 lg:w-12"
            data-glide-dir=">"
            aria-label="next slide"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-5 w-5"
            >
              <title>next slide</title>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </button>
        </div>
        {/*    <!-- Indicators --> */}
        
      </div>
      {/*<!-- End Carousel with indicators & controls inside --> */}
    </>
  )
}