import React, { useState } from 'react';

function ChatMessage({ sender, message }) {
  return (
    <div className={`flex gap-3 my-4 text-gray-600 text-sm flex-1 ${sender}`}>
      <span className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
        <div className="rounded-full bg-gray-100 border p-1">
          {sender === 'AI' ? (
            <svg stroke="none" fill="black" strokeWidth="1.5" viewBox="0 0 24 24" aria-hidden="true" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
            </svg>
          ) : (
            <svg stroke="none" fill="black" strokeWidth="0" viewBox="0 0 16 16" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
            </svg>
          )}
        </div>
      </span>
      <p className="leading-relaxed">
        <span className="block font-bold text-gray-700">{sender}</span>
        {message}
      </p>
    </div>
  );
}

function ChatApp() {
  const [inputValue, setInputValue] = useState('');
  const [togglechat,setTogglechat] = useState(false);
  const [messages, setMessages] = useState([
    { sender: 'AI', message: "Hi, how can I help you today?" },
    { sender: 'You', message: "fewafef" },
    { sender: 'AI', message: "Sorry, I couldn't find any information in the documentation about that. Expect answer to be less accurateI could not find the answer to this in the verified sources." }
  ]);

  const handleMessageSubmit = (e) => {
    e.preventDefault();
    if (inputValue.trim() !== '') {
      setMessages([...messages, { sender: 'You', message: inputValue }]);
      setInputValue('');
    }
  };

  return (
  <>
  
    <div className={`fixed  z-50 bottom-12 ${togglechat?'visible':'hidden'}  right-0 mr-4  p-6 rounded-lg  w-[440px] h-[634px] border-0 border-red-900`}>

    <div className="border-2 bg-white border-[#e5e7eb] p-6 rounded-lg h-full">
      <div className="flex flex-col space-y-1.5 pb-6">
        <h2 className="font-semibold text-lg tracking-tight">ChatNow</h2>
        <p className="text-sm text-[#6b7280] leading-3">With Science Sarathi ChatBot</p>
      </div>

      <div className="pr-4 h-[420px]" style={{ minWidth: '100%', display: 'table' }}>
        {messages.map((msg, index) => (
          <ChatMessage key={index} sender={msg.sender} message={msg.message} />
        ))}
      </div>

      <div className="flex items-center pt-0">
        <form onSubmit={handleMessageSubmit} className="flex items-center justify-center w-full space-x-2">
          <input
            className="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
            placeholder="Type your message"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <button
            type="submit"
            className="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6] h-10 px-4 py-2"
          >
            Send
          </button>
        </form>
      </div>
    </div>
    </div>

    <button className={`fixed  z-50 bottom-2  right-0 mr-4  p-4 rounded-full  bg-transparent text-white border-0 border-red-900`}
    onClick={() => {
        setTogglechat(!togglechat);
      }}
    >
            <img width="56" height="56" src="https://img.icons8.com/material/96/000000/chatbot.png" className='' alt="chatbot"/>
    </button>
    </>
  );
}

export default ChatApp;
