import React from "react";
import FaqData from "./FaqData";
const Faq = () => {
  return (
    <>
      <div class="w-full border-0  border-red-600 mx-auto p-5 bg-white min-h-sceen">
        <div class="flex flex-col items-center">
          <h2 class="font-bold text-5xl mt-5 tracking-tight">FAQ</h2>
          <p class="text-neutral-500 text-xl mt-3">Frequenty asked questions</p>
        </div>
        <div class="grid divide-y divide-neutral-200 max-w-3xl mx-auto mt-8">
          {/* accordian start  */}
          {FaqData.map((item) => {
            return (
              <div class="py-5">
                <details class="group">
                  <summary class="flex justify-between items-center font-medium cursor-pointer list-none">
                    <span className="font-semibold"> {item.question} </span>
                    <span class="transition group-open:rotate-180">
                      <svg
                        fill="none"
                        height="24"
                        shape-rendering="geometricPrecision"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path d="M6 9l6 6 6-6"></path>
                      </svg>
                    </span>
                  </summary>
                  <p class="text-neutral-600 mt-3 group-open:animate-fadeIn">
                    {item.answer}
                  </p>
                </details>
              </div>
            );
          })}

          {/* accordian end  */}
        </div>
      </div>
    </>
  );
};

export default Faq;
