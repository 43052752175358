import React, { useEffect, useState } from "react";
import "./Home.css";
import Hero from "../HeroSection/Hero";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AB from "../../Assets/ab.png";
import Testimonial from "../Testimonial/Testimonial";
import Card from "../Courses/Card";
import CarouselIndicatorsControlsInside from "../Courses/CarouselControlsInside";
import Update from "./Update";
import ChatApp from "../ChatApp/ChatApp";

const Home = () => {
  const [items, setItems] = useState([]);
  const [itemshi, setItemshi] = useState([]);

  useEffect(() => {
    const jsonData = [
      { "id": "Dec 11 2023","date":"Jan 26th 2024", "title": "Exciting News!", "content": "An internship program on A.I, Python, and Robotics is going to start from 11th Jan 2024 for the students of Navodaya Vidyalayas, Banka, Bihar." }
      
    ];
    const jsonDatahindi = [
      { "id": "Dec 11 2023","date":"Jan 26th 2024", "title": "Exciting News!", "content": "नवोदय विद्यालय, बांका, बिहार के छात्रों के लिए ए.आई, पायथन और रोबोटिक्स पर एक इंटर्नशिप कार्यक्रम 11 जनवरी 2024 से शुरू होने जा रहा है।" }
      
    ];

    setItems(jsonData);
    setItemshi(jsonDatahindi);
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(items);
  return (
    <div className="h-auto w-full border-0 border-red-800">
      <Hero />
      {/* about  */}
      <section className="border-0 border-red-800  tablet:p-4 mobiles:p-2 h-auto flex gap-4 justify-center tablet:mt-16 mobiles:mt-6 flex-wrap">
        <div className="laptop:w-[700px] mobiles:w-full border-0 border-green-800 tablet:flex mobiles:hidden flex-col justify-end items-center">
          <img
            // src="https://files.codingninjas.com/hero-image-25150.webp"
            src={AB}
            alt="robot"
            className="laptop:w-[350px] laptop:h-[350px] mobiles:w-[80%] mobiles:h-[90%]  rounded-xl  "
          />
        </div>
        <div className="laptop:w-[750px]  border-0 border-black flex justify-around flex-col mobiles:items-center tablet:items-start">
          <div className="w-[90%] h-full border-0 border-green-700 flex justify-around flex-col items-start roboto">
            <h1 className="laptop:text-[32px] mobiles:text-[32px] leading-9 text-[#040f26]  p-2 font-semibold drop-shadow-lg">
              Transforming Education In The Dynamic Landscape
            </h1>
            <p className="text-[18px] w-[80%]  text-justify	text[#36454F] leading-8 roboto p-2 ">
              Science-Sarathi leads the educational revolution, seamlessly
              integrating Robotics, Programming, AI, and Mechatronics. Committed
              to excellence, we provide adaptive solutions fostering
              21st-century skills—critical thinking, creativity, communication,
              and collaboration. Empowering students with transformative
              opportunities beyond classrooms, we prepare them for a future
              shaped by cutting-edge technologies, transforming education in a
              dynamic landscape.
            </p>
            <button className="btnhover"> Know More..</button>
          </div>
        </div>
      </section>
      {/* about end  */}
      <section className="border-0 p-4 border-red-600 w-full h-auto">
        <div className="flex flex-col justify-center items-center">
          <div
            className="tablet:text-[40px] mobiles:text-[35px] border-0 border-black w-full text-center  text-black p-7
           background-update font-bold leading-10 roboto"
          >
            <span className="bg-white w-auto tablet:p-4">
              Recent Updates on Science Sarathi
            </span>
          </div>
          <h1 className="text-[16px]  text-black pb-6 font-bold">
            Scroll down to check out our latest articles
          </h1>
          <div className="h-auto w-full  border-0 mt-10 border-red-600 justify-between items-center tablet:gap-1 mobiles:gap-4 flex flex-wrap">
            {/* anouncement 1  */}
            <Update items={items} lang="en" align="left" />
            <div className=" tablet:border-r-2 mobiles:border-t-2 mobiles:mx-auto border-gray-400 tablet:h-[400px] mobiles:h-0 mobiles:w-[90%] tablet:w-0 "></div>

            {/* anouncement 2  */}
            <Update items={itemshi} lang="hi" align="right" />
          </div>
        </div>
      </section>

      {/* course overview  */}

      <section className="h-auto">
        <div
          className="text-[40px] mt-20 border-0 border-black w-full text-center  text-black p-7
            font-bold  roboto"
        >
          <span className="bg-transparent w-auto p-4"> Well Formated Classes </span>
          <h1 className="text-[16px]  text-black pb-6 font-bold">
            1000+ Students believe
          </h1>
        </div>
        <div className="w-[80%] mx-auto pt-4">
          <CarouselIndicatorsControlsInside />
        </div>
      </section>

      {/* testimonial  */}
      <section className="h-auto w-full">
        {/* <Testimonial />  */}
      </section>

      {/* <ChatApp/> */}
    </div>
  );
};

export default Home;
